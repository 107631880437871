/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logout': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<mask id="svgicon_logout_a" maskUnits="userSpaceOnUse" x="1" y="1" width="16" height="16"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.75 1.004a.75.75 0 00-.75.75v14c0 .414.336.75.75.75h8a.75.75 0 100-1.5H2.5v-12.5h7.25a.75.75 0 100-1.5h-8zm13.068 7.231l-2.234-2.328 2.234 2.328zm1.487.854l-2.957 3.182a.454.454 0 100 0l2.957-3.182zm-1.471.146H6.557a.51.51 0 000 0h8.276zm-.716-1.3l-1.75-1.822a.821.821 0 01-.016-1.119.754.754 0 011.104 0l3.053 3.182c.299.311.306.808.017 1.12l-2.956 3.18a.754.754 0 01-1.104 0 .82.82 0 01-.017-1.118l1.693-1.822h-7.58a.815.815 0 01-.812-.8.785.785 0 01.788-.8h7.58z" _fill="#000"/></mask><g mask="url(#svgicon_logout_a)"><path pid="1" _fill="#898B9B" d="M0 .004h18v18H0z"/></g>'
  }
})
